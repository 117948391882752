import { Box } from '@material-ui/core';
import React, { useState } from 'react';

const FirstSection = (props) => {
    const [isGroomingActive, setGroomingActive] = useState(true);

    const handleGroomingClick = () => {
        setGroomingActive(true);
        props.setShow(true);
    };

    const handleVaccinationClick = () => {
        setGroomingActive(false);
        props.setShow(false);
    };

    return (
        <>
            <Box className='container'>
                <Box className={`menu_container ${isGroomingActive ? 'active' : ''}`} onClick={handleGroomingClick}>
                    <p className='menu_name'>Grooming</p>
                </Box>
                <Box className={`menu_container ${!isGroomingActive ? 'active' : ''}`} onClick={handleVaccinationClick}>
                    <p className='menu_name'>Vaccination</p>
                </Box>
            </Box>
            <hr className='divider' />
        </>
    );
};

export default FirstSection;