import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';

const Vaccination = () => {
    const [service, setService] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const clinicId = urlParams.get("clinic_id");
        if (clinicId) {
            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };  
            const apiUrl = `https://org.vetic.in/clinic-org/ratecard/?clinic_id=${clinicId}`;
            fetch(apiUrl, requestOptions)            
            .then(response => response.json())
            .then((result) => {
                setService(	
                    result.data[0].parent_service === "Vaccination"	
                      ? result.data[0].service_type	
                      : result.data[1].service_type	
                );
                setLoading(false);
            })
            .catch(error => console.log('error', error));
        }
    }, []);

    return (
        <Box className='second_main_container'>
            <Box className='box_container'>
                <table className='my-table'>
                    <thead> 
                        <tr className='table_row'>
                            <th className='name'>Name</th>
                            <th className='name'>Price</th>
                            <th className='name'>Discount</th>
                            <th className='name'>To Be Paid</th>
                        </tr>
                    </thead>
                    <tbody>
                    {loading ? (
                        <Box style={{textAlign: "center"}}><CircularProgress /></Box>
                        ) : (service.map((val, key) => (
                            <tr key={key} className='table_row' style={{ fontSize: '13px' }}>
                                <td>{val.service_type}</td>
                                <td>
                                    {val.service_price.map((priceItem, priceKey) => (
                                        <span key={priceKey}>{priceItem.price}</span>
                                    ))}
                                </td>
                                <td>
                                    {val.service_price.map((priceItem, priceKey) => (
                                        <span key={priceKey}>{priceItem.offers}{' '}</span>
                                    ))}
                                </td>
                                <td>
                                    {val.service_price.map((priceItem, priceKey) => (
                                        <span key={priceKey}>{priceItem.discounted_price}</span>
                                    ))}
                                </td>
                            </tr>
                        ))
                    )}
                    </tbody>
                </table>
            </Box>
        </Box>
    );
};

export default Vaccination;