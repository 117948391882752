import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { TiTick } from "react-icons/ti";
import { RxCross1 } from "react-icons/rx";
import React, { useEffect, useState } from 'react';
import { AiOutlineInfoCircle } from "react-icons/ai";
import { CircularProgress } from '@material-ui/core';

const style = {
  position: 'absolute',
  top: '75%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '85%',
  bgcolor: 'background.paper',
  borderRadius: '20px',
  boxShadow: 24
};

const SecondSection = () => {
  const [open, setOpen] = useState(-1);
  const [service, setService] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const clinicId = urlParams.get("clinic_id");   
    if (clinicId) {
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      const apiUrl = `https://org.vetic.in/clinic-org/ratecard/?clinic_id=${clinicId}`;    
      fetch(apiUrl, requestOptions)
      .then(response => response.json())
      .then((result) => {
        setService(	
          result.data[0].parent_service === "Grooming"	
          ? result.data[0].service_type	
          : result.data[1].service_type	
        );
        setLoading(false);
      })
      .catch(error => console.log('error', error));
    }
  }, []);

  const handleCloseModal = () => {
    setOpen(-1);
  };

  return (
    <Box className='second_main_container'>
      <Box className='second_header_container'>
        <p className='heading'>Grooming Services</p>
        <p className='text'>Based on your pet size, service prices will vary.</p>
      </Box>
      <Box className='third_main_container'>
        {loading ? (
          <Box style={{textAlign: "center"}}><CircularProgress /></Box>
          ) : (service?.map((serviceItem, i) => {
            return (
              <Box key={serviceItem.service_type}>
                <Box className='third_header_container'>
                  <p className='title'>{serviceItem.service_type}</p>
                  <Box className='icon_container' onClick={() => { setOpen(i) }}>
                    <AiOutlineInfoCircle />
                  </Box>
                </Box>
                <Box className='box_container'>
                  <table className='my-table'>
                    <thead>
                      <tr className='table_row'>
                        <th className='name'>Pet Weight</th>
                        <th className='name'>Price</th>
                        <th className='name'>Discount</th>
                        <th className='name' style={{width: "5rem"}}>To Be Paid</th>
                      </tr>
                    </thead>
                    <tbody>
                      {serviceItem.service_price.map((priceItem) => {
                        const match = priceItem.size ? priceItem.size.match(/\((.*?)\)/) : null;
                        const size = match ? match[1] : priceItem.size;
                        return (
                          <tr key={priceItem.id} className='table_row' style={{ fontSize: '11px' }}>
                            <td>{size}</td>
                            <td>{priceItem.price}</td>
                            <td>{priceItem.offers}</td>
                            <td>{priceItem.discounted_price}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Box>
              </Box>
            )
          })
        )}
        {open !== -1 && (
          <Modal
            open={open === -1 ? false : true}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style}>
              <Box className='popup_header_container'>
                <Box>
                  <p className='popup_header'>{service[open].service_type} Includes</p>
                </Box>
                <Box className="cross_icon" onClick={handleCloseModal}>
                  <RxCross1 />
                </Box>
              </Box>
              <hr style={{ width: "29.3rem", marginLeft: "-31px" }} />
              <Box className="points_main">
                <Box className='points_container'>
                  {service[open]["service_inclusions"] !== null ? (
                    service[open]["service_inclusions"].split(",").map((name, id) => (
                      <Box key={id} className='points'>
                        <Box><TiTick /></Box>
                        <p>{name}</p>
                      </Box>
                    ))
                    ) : (
                    <p>No data available</p>
                  )}
                </Box>
              </Box>
            </Box>
          </Modal>                  
        )}
      </Box>
    </Box>
  );
};

export default SecondSection;