import { Box } from '@material-ui/core';
import RateCardIndex from './components/RateCard';

const App = () => {
  return (
    <Box>
      <RateCardIndex />
    </Box>
  );
};

export default App;