import React from 'react';
import { Box } from '@material-ui/core';

const Header = () => {
  return (
    <Box className='main_container'>
        <Box className='header_container'>
            <h3 className='header_text'>Non-Member Rate Card</h3>
        </Box>
    </Box>
  );
};

export default Header;