import "./RateCard.css";    
import Header from './Header';
import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import Vaccination from './Vaccination';
import FirstSection from './FirstSection';
import SecondSection from './SecondSection';

const RateCardIndex = () => {
    const [show, setShow] = useState(true);

    return (
        <Box>
            <Header />
            <FirstSection setShow={setShow}/>
            {show ?
                <SecondSection />:
                <Vaccination />
            }
        </Box>
    );
};

export default RateCardIndex;